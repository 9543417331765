<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBContainer class="mt-3" bg="white">
    <MDBRow>
      <MDBCol>
        <MDBCard>
          <MDBCardBody>
            <form @submit="Submit">
              <MDBCardText>
                <MDBRow class="mb-3">
                  <MDBCol md="4">
                    <label>免費天數設定</label>
                  </MDBCol>
                  <MDBCol md="8">
                    <input type="text" class="form-control" v-model="Form.month" />
                    <span class="text-danger">{{ monthError }}</span>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="4">
                    <label>免費天數設定(已綁定經銷商)</label>
                  </MDBCol>
                  <MDBCol md="8">
                    <input type="text" class="form-control" v-model="Form.refer_month" />
                    <span class="text-danger">{{ refer_monthError }}</span>
                  </MDBCol>
                </MDBRow>
              </MDBCardText>
              <MDBRow>
                <MDBCol>
                  <MDBBtn color="primary" type="submit" :disabled="SubmitLoading">
                    {{ $t("COMMON.SAVE") }}
                    <span
                      class="spinner-border Loading"
                      :class="{ 'd-none': !SubmitLoading }"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </span>
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBCardText,
} from "mdb-vue-ui-kit";
import Breadcrumbs from "@/components/Breadcrumbs";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { useForm, useField } from "vee-validate";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import { ref, reactive } from "vue";

export default {
  name: "EmailManage",
  components: {
    MDBContainer,
    Breadcrumbs,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBBtn,
  },
  setup() {
    const SubmitLoading = ref(false);
    const sendMailLoading = ref(false);
    const uuid = "6";
    const baseUrl = `Admin/v1/CommoditySettings`;
    const i18n = useI18n();

    const basicSchema = {
      month: yup.string().required().label("免費天數"),
      refer_month: yup.string().required().label("免費天數"),
    };

    const FormSchema = yup.object(Object.assign(basicSchema));

    const { handleSubmit } = useForm({
      validationSchema: FormSchema,
    });

    const { value: month, errorMessage: monthError } = useField("month");
    const { value: refer_month, errorMessage: refer_monthError } = useField("refer_month");

    const Form = reactive({
      month,
      refer_month,
    });

    // 取得 email sysconfig 資訊
    const getSettings = async () => {
      return await ApiService.get(baseUrl, uuid).then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data;
          Form.month = responseData.month;
          Form.refer_month = responseData.refer_month;
        }
      });
    };

    // 資料送出
    const Submit = handleSubmit((values) => {
      SubmitLoading.value = true;
      ApiService.update(baseUrl, uuid, values).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: `${i18n.t("COMMON.EDIT")}${i18n.t("COMMON.SUCCESS")}`,
            icon: "success",
            confirmButtonClass: i18n.t("COMMON.CONFIRM"),
          });

          SubmitLoading.value = false;
        }
      });
      return false;
    });


    return {
      getSettings,
      SubmitLoading,
      sendMailLoading,
      Form,
      Submit,
      monthError,
      refer_monthError,
    };
  },
  async mounted() {
    Promise.all([await this.getSettings()]).then(() => {
      this.PageLoading = false;
    });
  },
};
</script>
